<template>
  <div class="max-w-20 text-center">
    <card type="testimonial" header-classes="card-header-avatar" style="padding: 2rem">
      <img
        height="90px"
        src="https://imgix.cosmicjs.com/dbcd2d20-afde-11ec-97bc-19d12908cbbe-image.png?auto=format"
              alt="Card image"
      />
      <hr>
      <p
        class="tulum-party-subtitle mt-0 mb-10"
        style="letter-spacing: 0.4em; font-size: 1rem; margin-top: 1px"
      >
        <b>Who we are?</b>
      </p>

      <h3 class="tulum-party-subtitle gradient2">THE BEGINNING</h3>
      <p class="text-gray-300">
        We created our website back on 2018 as a music fan "side project" with a goal in mind.
        <br>
        <br>
        <strong>"Help party-goers to discover where the party is"</strong>
        <br>
      </p>

      <p>
        Back then finding the perfect party at the right time was a real challenge, as there were limited options and information often hard to come by.
      </p>
      <br>


      <img v-lazy="astronaut" height="120px" >
      <br>

      <hr>
      <p>
        Over time, we have continued to provide party information with out stop and bring together locals and travelers in the Riviera Maya to dance and have fun.
        <br>

        <br>
      Our goal has not changed much since then, but not the circumstances.

      <hr>
      <h3 class="tulum-party-subtitle ">THE COVID REVOLUTION</h3>
<p>
  During the onset of the COVID-19 pandemic, our website saw an influx of visitors seeking information and guidance on Tulum's party scene. Brave travelers sought to escape the constraints of lockdowns and curfews and were looking for ways to safely enjoy the nightlife in Tulum. As event promoters with a passion for music, we were determined to provide reliable and up-to-date information to help these travelers navigate Tulum's lively party scene. Our mission has always been to support and promote the party scene, and we continued to do so even during these challenging times.      </p>
      <br>
      <strong>

        During the pandemic, Tulum became a popular destination for travelers seeking a more natural environment during lockdowns and curfews. As a result, many people temporarily or permanently relocated to Tulum</strong>
     <br>
      <br>


      <p>As a web-based platform, we were able to serve as a hub for underground activity, even in the face of restrictions on major media channels and social networks.</p>
      <p>Our work during these challenging times provided us with a sense of purpose and helped us navigate the darkness of the COVID pandemic.


      <br>
      <h3 class="tulum-party-subtitle">HOW WE OPERATE?</h3>

      <p>
        As event promoters, the Tulum party crew operates with a passion for music and a commitment to providing top-notch party experiences. The team is small and independent, and they have built their reputation through hard work and word of mouth. They believe in creating a genuine and authentic party scene, and have refused to engage in pay-for-play tactics, such as accepting payment for advertising. Instead, they have relied on the quality of their events and their commitment to the party scene to spread the word and attract partygoers from around the world to Tulum
      </p>
      <br>
      <strong>Our recommendations are truly based on data, past experience and party people feedback</strong>
      <hr>
      <p>
        We sustain our operations through commissions on ticket sales and event reservations.  As dedicated promoters of the Tulum party scene, we encourage partygoers to purchase tickets and make reservations through us, as this support helps to keep our mission alive.
      </p>

      <br>
      <p>


      In addition, we reinvest a portion of our commissions back into the party scene and the promotion of underground artists. This not only helps to support the local music community but also ensures that the party scene in Tulum remains vibrant and exciting for years to come. We are more than just event promoters, we are champions of the music and the party scene.
      </p>

      <hr>
      <br />
      Drop us an Email if you want to become our partner, ambassador or want to
      contribute. We are open to ideas & feedback:
      <br />

      <a href="mailto:ronnie@cabo.party" class="font-weight-900">Send us an email.</a>
      <hr />
      <br />
      <img
        src="https://imgix.cosmicjs.com/2372be70-7b73-11eb-a1a4-e19a4cdcba0a-flag-400.png"
        height="30px"
      />
      <hr>
      <strong>www.tulum.party !</strong>
      <br>
      <small>
        The passion project that started as a side hustle, but grew into something much larger, providing a beacon of hope during a dark and uncertain time.     </small>



      <p>We are proudly Mexicans !</p>

      <strong>Mira mama ya puedo vivir de la musica !</strong>


      <hr>

      <h6>
        The Tulum party crew has been a vital player in the development and growth of the Tulum party scene. Throughout all stages, they have consistently supported the scene, helping to keep the parties going, no matter what challenges they faced. Their dedication and commitment to the party scene has contributed to the creation of the vibrant and unforgettable atmosphere that Tulum is known for

      </h6>
    </card>
  </div>
</template>

<script>
export default {
  metaInfo: () => ({
    title: 'TULUM.PARTY | About Us. Who we are?',
    meta: [
      { name: 'robots', content: 'noindex' },
      {
        name: 'description',
        content: 'Tulum Parties, Nightlife and Techno Music.',
      },
      {
        name: 'keywords',
        content:
          'Tulum Party, About Us, Who we are',
      },
    ],
  }),
  data: () => {
    return {
      modal: {},
      astronaut: "https://imgix.cosmicjs.com/0efaa130-9744-11ed-93ee-cb9a2cd68754-image.png?w=440&h=120&fit=clamp&duotone=black,00bbff&duotone-alpha=70",
      name: null,
      email: null,
      message: null,
      events: [],
    };
  },
  methods: {
  },
};
</script>

<style scoped></style>
